
import request from '../utils/request'

export function articleDetail (t1) {

    return request({
      url: `/website/article/${t1}/detail`,
      method: 'post'
    })
  }
  
