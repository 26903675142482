


import request from '../utils/request'

export function userInfo () {
    return request({
      url: `/website/user/info`,
      method: 'post'
    })
  }
