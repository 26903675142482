<template>

    <div
      class="index_content_header flex-wrap-st align-ctc"
       :class="`${navStyle == 'blacks' ? 'blackNavS' : ''} ${scrollStatus ? 'header_scroll_style' : ''}`"
    >

     


    <div class="flex-wrap-st index_header_content flex-wrap justify-ctc justify-btw align-ctc">
      <div class="index_content_header_logo" @click="goHomePage">
          <div v-if='!scrollStatus'>
            <img
            v-if="navStyle == 'black' || navStyle == 'blacks'"
            src="@/assets/mafengEduBlackLogo.png"
            alt=""
            />
            <img v-else src="@/assets/mafengEdulogo.png" alt="" />
        </div>
        <div v-else>
            <img src="@/assets/mafengEdulogo.png" alt="" />
        </div>
      </div>

      <div
        class="flex-wrap-st index_content_header_nav"
        :class="navStyle == 'blacks' ? 'blackNav' : ''"
      >
        <div>
          <router-link
            to="/index"
            :class="currentIndex == 0 ? 'currentPage' : ''"
            >首页</router-link
          >
          <!-- <a href="javascript;"/  >日语</a> -->
          <router-link to="/jp" :class="currentIndex == 1 ? 'currentPage' : ''">日语</router-link>
          <router-link to="/newsList" :class="currentIndex == 2 ? 'currentPage' : ''">精选报告</router-link>
          <!-- <a href="">阅读</a> -->
          <router-link
            to="/issIndex"
            :class="currentIndex == 3 ? 'currentPage' : ''"
            >智慧校园</router-link
          >
        </div>
        <div
          class="index_content_header_login_wrap flex-wrap-st align-ctc"
          v-if="!loginStatus"
        >
          <router-link to="/login">登录</router-link>
          <label :class="navStyle == 'blacks' ? 'blacklabel' : ''"></label>
          <router-link to="/register">注册</router-link>
        </div>
        <div
          class="index_content_header_login_wrap flex-wrap-st align-ctc"
          v-else
          :class="navStyle == 'blacks' ? 'blackLink' : ''"
        >
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userData.user_name
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="goUserCenter">个人中心</div>
              </el-dropdown-item>
              <el-dropdown-item>
                 <div  @click="goLoginPage">切换账号</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="goLoginOut">退出登录</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
     <div @click="goTop" class="photo-back-top to-top" style="cursor: pointer; display: block;">
                <span>
                    <label></label>
                </span>
            </div>
    </div>

</template>

<script>
import $ from 'jquery'
import { getToken, removeToken } from "@/utils/auth"; // get token from cookie
import { userInfo } from "@/api/userInfo";
import { logout } from "@/api/logout";
export default {
  props: {
    currentIndex: {
      type: Number,
      default: 0,
    },
    navStyle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loginStatus: true,
      scrollStatus: false,
      userData: {
        mobile: "",
      },
    };
  },
  methods: {
    goHomePage(){
      this.$router.push('/index')
    },
    goLoginPage(){
       logout().then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;

          if (data.error === 0) {
            sessionStorage.removeItem("userinfo");
            removeToken();

            this.$router.push('/login');
          }
        }
      });
    },
    goLoginOut() {
      logout().then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;

          if (data.error === 0) {
            sessionStorage.removeItem("userinfo");
            removeToken();

            this.$router.go(0);
          }
        }
      });
    },
    goUserCenter() {
      this.$router.push("/userCenter");
    },
     handleScroll (e) {
         let scrolltop= document.documentElement.scrollTop
      
      if(scrolltop > 0) {
          this.scrollStatus = true
          $('.index_content_header').addClass('header_scroll_style')
          $('.photo-back-top').addClass('photo-back-show')
      } else {
          this.scrollStatus = false
          $('.index_content_header').removeClass('header_scroll_style')
          $('.photo-back-top').removeClass('photo-back-show')
      }
    },
    getUserInfo() {
      userInfo().then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data;
          this.userData = data.data;
        }
      });
    },
    goTop() {
          window.scrollTo(0,0)
      },
  },
  created() {
    if (getToken()) {
      this.getUserInfo();
    } else {
      this.loginStatus = false;
    }
  },
  mounted () {
       window.addEventListener('scroll', this.handleScroll)
  }
};

</script>

<style scoped>
.blackNavS{
    box-shadow: 0 3px 6px rgb(125 125 125 / 20%); 
}
.newsDeatil_header {
  width: 100%;
  height: 80px;
  box-shadow: 0 3px 6px rgba(125, 125, 125, 0.2);
}
.newsDeatil_header_inset {
  width: 1200px;
  margin: auto;
}
.newsDeatil_header .index_content_header_nav a {
  color: #333333;
}

.newsDeatil_header .index_content_header_login_wrap label {
  background: #dfdfdf;
}
.index_header_content{
    min-width: 1200px;
    margin: auto;
    height: 80px;
}
.index_content_header {

position: -webkit-sticky;
    position: sticky;
    top:0;
     height: 80px;
    
    color: #fff;
    font-size: 30px;
    line-height: 80px;
    z-index: 999;
    transition: .2s all;
    
}
.index_content_header_logo {
  width: 180px;
  cursor: pointer;
}
.index_content_header_logo img {
  width: 100%;
  display: block;
}
.blacklabel{
    background: #DFDFDF !important;
}

.index_content_header_login_wrap {
  margin-left: 49px;
}
.index_content_header_nav a {
  font-size: 16px;
  color: #ffffff;
  padding: 0 21px;
  text-decoration: none;
  font-weight: lighter;
}
.index_content_header_login_wrap label {
  width: 1px;
  height: 25px;
  background: #ffffff;
}
.currentPage {
  font-weight: bold !important;
}
.blackNav a {
  color: #333333;
}
.blackLink .el-dropdown-link {
  color: #333333;
}
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}
.el-icon-arrow-down {
  font-size: 14px;
}
.header_scroll_style{
    background: rgba(0,0,0,.5) !important;
  
}
.header_scroll_style a{
    color: #ffffff  !important;
}
.header_scroll_style .el-dropdown-link {
  color: #ffffff;
}
.photo-back-top {
    opacity: 0;
    visibility: hidden;
    width: 40px;
    height: 40px;
    background: #D4D4D4;
    position: fixed;
    right: 20px;
    border-radius: 100%;
    z-index: 9999;
    bottom: 20px;
    -webkit-transition: all 350ms cubic-bezier(0.215,.61,.215,1);
}
.photo-back-show{
    opacity: 1;
    visibility: visible
}
.photo-back-top span{
    height:14px;
    width:14px;
    display:block;
    position:absolute;
    left:50%;
    margin-left:-7px;
    top:50%;
    margin-top:-7px;
    text-align:center;
    overflow:hidden;
    opacity:1;
    }
.photo-back-top span::after,.photo-back-top span::before{
    position:absolute;
    content:'';
    top:0;
    left:0;
    height:2px;
    width:14px;
    background:#898c8f;
    }
.photo-back-top span::after:hover{
    background:white;
    }
.photo-back-top span::after{
    -webkit-transform:rotate(45deg);
    -moz-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    top:-1px;
}
.photo-back-top span::before{
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -ms-transform:rotate(-45deg);
    -o-transform:rotate(-45deg);
    top:-1px;
}

.photo-back-top:hover span::after {
    background:white !important;
}
.photo-back-top:hover span::before {
    background:white !important;
}
.photo-back-top span label{
    width: 2px;
    height: 14px;
    background: #898c8f;
    opacity: 1;
    display: inline-block;
    position: absolute;
    left: 50%;
    margin-left: -1px;
}
.photo-back-top:hover{
    transform:rotate(720deg);
    background:#222222;
}
.photo-back-top:hover label{
background:white;
}
.photo-back-top span:hover ::after{
background:white
}
</style>