<template>
  <div>
    <div class="index_footer">
      <div class="index_footer_inset flex-wrap-st justify-btw">
        <div>
          <div class="footer_logo">
            <div><img src="@/assets/footer_logo.png" alt="" /></div>
            <div class="footer_com_intro">{{ webInfo.web_slogan }}</div>
          </div>

          <div class="footer_about_us flex-wrap-st">
            <div class="focus_me">
              <p>关注我们</p>
              <div class="focus_me_icon">
                <div class="ercode">
                  <img src="@/assets/ercode.png" alt="" />
                </div>
                <img src="@/assets/wechart.png" alt="" />
              </div>
            </div>
            <div class="footer_about_us_info">
              <p class="flex-wrap-st align-ctc">
                <img
                  style="width: 12px; margin-right: 13px;"
                  src="@/assets/address_icon.png"
                  alt=""
                />
                {{ webInfo.web_address }}
              </p>
              <p class="flex-wrap-st align-ctc">
                <img
                  style="width: 14px; margin-right: 13px;"
                  src="@/assets/email_icon.png"
                />
                咨询邮箱: {{ webInfo.web_email }}
              </p>
            </div>
          </div>
        </div>
        <div class="footer_nav">
          <ul class="flex-wrap-st">
            <li style="margin-right: 90px;">
              <div class="footer_nav_title">快捷入口</div>

              <!-- <a href="javascript;"/  >日语</a> -->
              <p><router-link to="/">首页</router-link></p>
              <p><router-link to="/jp">日语</router-link></p>

              <p><router-link to="/issIndex">智慧校园</router-link></p>
            </li>
            <li style="margin-right: 90px;">
              <div class="footer_nav_title">合作伙伴中心</div>
              <p>
                <!-- <router-link to="/contactUs">联系我们</router-link> -->
                <label @click="goContactUs">联系我们</label>
              </p>
            </li>
            <li>
              <div class="footer_nav_title">服务热线</div>
              <p>{{ webInfo.web_time }}</p>
              <div class="footer_nav_title" style="margin-bottom: 5px;">
                客服电话
              </div>
              <p>{{ webInfo.web_mobile }}</p>
              <!-- <div class="footer_nav_title"  style="margin-bottom: 0px;">客服微信</div>
                            <p>{{webInfo.web_weixin}}</p> -->
            </li>
          </ul>
        </div>
      </div>

      <div class="footer_s">
        <div class="footer_s_inset flex-wrap-st justify-ctc align-ctc">
          <label>{{ webInfo.web_copyright }}</label>
          <a
            target="_blank"
            href="https://beian.miit.gov.cn"
            style="display:inline-block;text-decoration:none;"
            class="footer-jump-link"
          >
            {{ webInfo.web_icp }}
          </a>
          <label>{{ webInfo.erb_name }}</label>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033487"
            style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
            ><img src="@/assets/beian.png" style="float:left;" />
            <a
              style="display:inline-block;text-decoration:none;margin: 0 0 0 5px;"
              class="footer-jump-link"
            >
              京公网安备 11010802033487号
            </a></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { webBaseInfo } from "../api/index";
export default {
  data() {
    return {
      webInfo: {},
    };
  },
  methods: {
    goContactUs() {
      this.$router.push({
        path: "/contactUs",
        query: {
          type: "index",
        },
      });
    },
    webBaseInfoFc() {
      webBaseInfo().then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          if (data.error == 0) {
            this.webInfo = data.data;
          } else {
            this.$message.error(data.message);
          }
        }
      });
    },
  },
  created() {
    this.webBaseInfoFc();
  },
};
</script>

<style scoped>
.index_footer {
  width: 100%;
  height: auto;
  background: #1f2024;
}
.index_footer_inset {
  width: 1200px;
  margin: auto;
  padding: 74px 0 28px 0;
}
.footer_about_us {
  color: #ffffff;
}
.footer_nav {
}
.footer_nav a {
  text-decoration: none;
  color: #cbcccc;
  font-size: 14px;
  display: inline-block;
  margin: 0;
  padding: 0;
}
.footer_nav li {
  list-style: none;
}
.footer_nav p {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  color: #e9e9e9;
  font-size: 14px;
}
.footer_nav_title {
  color: #a5a5a7;
  margin-bottom: 16px;
}
.footer_com_intro {
  font-size: 14px;
  color: #a5a5a7;
  line-height: 24px;
  margin-top: 20px;
}
.footer_about_us {
  margin-top: 73px;
}
.footer_about_us_info {
  margin-left: 32px;
  font-size: 14px;
  color: #a5a5a7;
  padding-top: 13px;
}
.footer_about_us_info p {
  line-height: 24px;
  margin-bottom: 6px;
}
.focus_me {
  position: relative;
  margin-top: 13px;
}
.focus_me_icon:hover .ercode {
  opacity: 1;
  display: block;
}
.focus_me_icon:hover {
  background: #fdd000;
}
.focus_me_icon {
  width: 36px;
  height: 36px;
  margin: auto;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}
.ercode {
  width: 100px;
  position: absolute;
  top: -114px;
  display: none;
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  transition: 0.3s;
}
.ercode img {
  width: 100%;
  display: block;
}
.focus_me_icon img {
  width: 100%;
  display: block;
}
.focus_me p {
  font-size: 14px;
  color: #a5a5a7;
  margin-bottom: 14px;
}
.footer_s {
  border-top: 1px solid #4b4c4f;
  padding: 26px 45px;
  overflow: hidden;
}
.footer_s_inset {
  width: 1200px;
  margin: auto;
  text-align: center;
}
.footer_s_inset label,
.footer_s_inset a {
  color: #a5a5a7;
  font-size: 12px;
  margin-right: 10px;
}

.footer-jump-link:hover {
  color: #ffffff;
  cursor: pointer;
}
</style>
