
import request from '../utils/request'

export function articleList (t1, t2) {
  
    return request({
      url: `/website/article/list?page=${t1}&limit=${t2}`,
      method: 'post'
    })
  }

export function linksList() {
    return request({
        url: `/website/links/list`,
        method: 'get',
    })
}

export function webBaseInfo() {
    return request({
        url: `/website/web/base/info`,
        method: 'get'
    })
}

export function artiteminfo(t1) {
    const data = {
        id: t1
    }
    return request({
        url: `/website/pay/article`,
        method: 'post',
        data
    })
}
  
